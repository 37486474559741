const telegramSVG = () => {

    return (
        <svg width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="35" height="35" rx="7.5" fill="none" strokeOpacity="0.8"/>
            <path fillRule="evenodd" fill="" clipRule="evenodd" d="M8.60057 17.8588C10.2839 16.9315 12.163 16.1576 13.9187 15.3798C16.9392 14.1058 19.9718 12.8538 23.0349 11.6882C23.6309 11.4896 24.7017 11.2954 24.8067 12.1786C24.7492 13.4288 24.5127 14.6716 24.3504 15.9145C23.9387 18.6476 23.4627 21.3713 22.9986 24.0955C22.8387 25.0028 21.702 25.4726 20.9747 24.8919C19.2268 23.7112 17.4654 22.5421 15.7398 21.334C15.1746 20.7596 15.6987 19.9348 16.2036 19.5247C17.6432 18.1059 19.17 16.9005 20.5344 15.4084C20.9024 14.5196 19.815 15.2687 19.4563 15.4982C17.4854 16.8563 15.5628 18.2974 13.4849 19.4911C12.4235 20.0753 11.1864 19.576 10.1255 19.25C9.17421 18.8561 7.78028 18.4594 8.60048 17.8588L8.60057 17.8588Z" 
            />
        </svg>
    )
}

export default telegramSVG



