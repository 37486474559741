const vkSVG = () => {

    return (
        <svg width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5"  width="35" height="35" rx="7.5" fill="none" strokeOpacity="0.8"/>
            <path fillRule="evenodd" fill="" clipRule="evenodd" d="M26.4875 14.3797C26.6262 13.9851 26.4875 13.6981 25.8729 13.6981H23.8308C23.3153 13.6981 23.0774 13.9492 22.9387 14.2183C22.9387 14.2183 21.8879 16.5141 20.4207 18.0027C19.9449 18.4332 19.7268 18.5767 19.4691 18.5767C19.3303 18.5767 19.1519 18.4332 19.1519 18.0386V14.3617C19.1519 13.8954 18.9933 13.6802 18.5571 13.6802H15.3453C15.0281 13.6802 14.8299 13.8954 14.8299 14.1106C14.8299 14.5591 15.5634 14.6667 15.6427 15.9222V18.6484C15.6427 19.2403 15.5238 19.3479 15.266 19.3479C14.5721 19.3479 12.8869 17.0342 11.8758 14.3976C11.6775 13.8775 11.4793 13.6802 10.9638 13.6802H8.90191C8.30714 13.6802 8.20801 13.9313 8.20801 14.2003C8.20801 14.6846 8.90191 17.1239 11.4396 20.3524C13.1249 22.5585 15.5238 23.7422 17.6848 23.7422C18.9933 23.7422 19.1519 23.4732 19.1519 23.0248V21.3568C19.1519 20.8187 19.2708 20.729 19.6872 20.729C19.9846 20.729 20.5199 20.8725 21.7292 21.9307C23.117 23.1862 23.355 23.7602 24.1282 23.7602H26.1703C26.765 23.7602 27.0426 23.4911 26.884 22.971C26.7056 22.4509 26.0315 21.6976 25.1591 20.8007C24.6833 20.2986 23.9696 19.7426 23.7515 19.4735C23.4541 19.1148 23.5334 18.9713 23.7515 18.6484C23.7317 18.6484 26.2297 15.4559 26.4875 14.3797Z" 
            />
        </svg>
    )
}

export default vkSVG