const youtubeSVG = () => {

    return (
        <svg width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="35" height="35" rx="7.5" fill="none" strokeOpacity="0.8"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M24.001 13.0496C24.6619 13.2309 25.1824 13.7653 25.359 14.4439C25.68 15.6738 25.68 18.24 25.68 18.24C25.68 18.24 25.68 20.8061 25.359 22.036C25.1824 22.7146 24.6619 23.249 24.001 23.4305C22.8032 23.76 18 23.76 18 23.76C18 23.76 13.1968 23.76 11.999 23.4305C11.338 23.249 10.8175 22.7146 10.6409 22.036C10.32 20.8061 10.32 18.24 10.32 18.24C10.32 18.24 10.32 15.6738 10.6409 14.4439C10.8175 13.7653 11.338 13.2309 11.999 13.0496C13.1968 12.72 18 12.72 18 12.72C18 12.72 22.8032 12.72 24.001 13.0496ZM16.56 16.0802V20.8802L20.4 18.4803L16.56 16.0802Z" 
            />
        </svg>
    )
}

export default youtubeSVG













