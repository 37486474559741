const okSVG = () => {

    return (
        <svg width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="35" height="35" rx="7.5" fill="none" strokeOpacity="0.8"/>
            <path d="M19.9191 22.2622L22.5726 24.8238C23.1162 25.3474 23.1162 26.1978 22.5726 26.722C22.0296 27.2462 21.1494 27.2462 20.607 26.722L17.9981 24.2048L15.3915 26.722C15.1198 26.9838 14.7636 27.1149 14.4075 27.1149C14.0519 27.1149 13.6963 26.9838 13.4245 26.722C12.8815 26.1978 12.8815 25.348 13.424 24.8238L16.0777 22.2622C15.1115 22.0497 14.1797 21.6802 13.3214 21.1604C12.6719 20.7651 12.4768 19.9369 12.8861 19.3094C13.2942 18.681 14.1524 18.4919 14.8031 18.8872C16.7462 20.0671 19.2495 20.0674 21.1937 18.8872C21.8444 18.4919 22.7024 18.681 23.1113 19.3094C23.5206 19.9364 23.3249 20.7651 22.6754 21.1604C21.8171 21.6807 20.8853 22.0497 19.9191 22.2622Z" fillOpacity="0.8"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M13.2401 13.9419C13.2401 16.4679 15.369 18.5226 17.9867 18.5226C20.6049 18.5226 22.7332 16.4679 22.7332 13.9419C22.7332 11.4151 20.6049 9.35986 17.9867 9.35986C15.369 9.35986 13.2401 11.4151 13.2401 13.9419ZM19.9519 13.9414C19.9519 12.8953 19.0704 12.0446 17.9866 12.0446C16.9037 12.0446 16.0213 12.8953 16.0213 13.9414C16.0213 14.9868 16.9037 15.838 17.9866 15.838C19.0704 15.838 19.9519 14.9868 19.9519 13.9414Z" 
            />
        </svg>
    )
}

export default okSVG



